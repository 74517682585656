body {
  color: #000;
  background: #F9F9F9;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.1px;
  font-size: 16px;
  line-height: 25px;
}

.pt-31{
  justify-content: center;
  background-image: url('images/bg_ginger1.png');
  height: 250px;
  background-size: cover;
  align-items: center;
  text-align: center;
  /* margin-left: 30%; */
  border-radius: 20px;
  border-bottom: solid;
  border-color: rgba(81, 21, 21, 0.666);
}
/* .pt-text1{
  width: 50%;
} */

.pt-31 h3{
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  /* margin-left: 30%; */
  color: rgb(81, 21, 21);
}
.pt-text{
  width: 50%;
  margin-left: 23%;
  font-size: 12px;
}
.footer_text{
  background-color: #2c451f;
  color: #ffffff;
  height: 50px;
  padding: 13px;
  margin-top: -30px;
}
.circle1{
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 30px;
  border: solid;
  border: 1;
  border-color: rgba(81, 21, 21, 0.666);
  margin-left: 45%;
  color: rgb(81, 21, 21);
  /* align-items: center; */
  padding: 5px;
  /* font-size: 8px; */
}
/* .carousel h3{
  margin: 20px;
} */
.circles{
  display: flex;
  margin-top: -25px;
  /* padding: 10%; */
  
}

.pha{
  width: 30%;
  align-items: center;
  margin: 5%;
  margin-top: -0px;
}


.result_heading h4{
  margin-left: 15%;
  /* margin-top: 50%; */
}






.container{
  /* height: 50px; */
  margin-top: -20px;
}
.loading {
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.modal-dialog{
  /* margin-top: 0px; */
  width: 100%;
  /* background-color: #0093E5; */
  
}

.modal{
  /* background-color: #38B000; */
}


.modal-content{
  background-color: rgba(225, 225, 225, 0.87) !important;
  width: 100%;
  /* top: -80px; */
  /* margin-top: 0px; */
}

* {
  margin: 0px;
  padding: 0px;
}

.bg-image-home {
  background-image: url("images/home_bg.png");
  background-size: cover;
  height: 100vh;
}

.bg-image-Apple {
  background-image: url("images/apple_bg.png");
  background-size: cover;
  height: 100vh;
}

.bg-image-Banana {
  background-image: url("images/apple_bg.png");
  background-size: cover;
  height: 100vh;
}

.bg-image-Banana {
  background-image: url("images/banana_bg.png");
  background-size: cover;
  height: 100vh;
}

.bg-image-Pineapple {
  background-image: url("images/pine_bg.png");
  background-size: cover;
  height: 100vh;
}

.bg-image-Mango {
  background-image: url("images/mango_bg.png");
  background-size: cover;
  height: 100vh;
}

.bg-image-Papaya {
  background-image: url("images/papaya_bg.png");
  background-size: cover;
  height: 100vh;
}

.bg-image-lemon {
  background-image: url("images/lemon_bg.jpg");
  background-size: cover;
  height: 100vh;
}

.bg-image-Ginger {
  background-image: url("images/ginger_bg.png");
  /* background-color: #F5DEB3; */
  background-size: cover;
  height: 100vh;
  /* overflow: scroll; */
}


.logo {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -1px;
  position: relative;
  top: 0%;
  z-index: 1080;
  height: 100px;
}

.bg-image-Muskmelon {
  background-image: url("images/mask.png");
  background-size: cover;
  height: 100vh;
}


.img-camera {
  position: absolute;
  left: 37%;
  top: 12%;
  vertical-align: middle;
  width: 25%;
}

.img-camera-bg {
  border: 1.5px dashed #bdb9b9;
  border-radius: 20px;
  height:50%;
}

.color-green {
  color: #38B000;
}

.color-white {
  color: #fff;
}

input {
  background-color: #ffffff;
  border-color: #cdcdcd;
  border-width: 1px;
  padding: 10px;
}


.btn-fruit {
  border-radius: 7px;
  padding: 9px;
  border: none;
  font-size: 14px;
}

.flash-font {
  font-size: 14px;
}

.color-red {
  color: red;
}

.header-font-fruit {
  font-size: 33px;
}

@media (min-width: 800px) {
  .modal-xl {
    max-width: 90%;
  }
  .modal-content{
    top: 0%;
    margin-top: -2%;
  }
  .img-camera{
    width: 50px;
    /* left: 10%; */
  }
}

.font-size-small {
  font-size: 13px;
}

.result-box {
  background-color: #e3ffd6;
  border: 1px solid #b0ef92;
  border-width: 1px;
  margin: 10px;
  padding: 12px 20px 22px 20px;
  border-radius: 7px;
  /*box-shadow: 0px 2px 7px #17470f;*/
}

.result-box-1 {
  padding: 12px 30px 34px 29px;
}

.result-scale {
  width: 9.5%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  padding: 6px 12px;
}

.btn-scale-asc-1,
.btn-scale-desc-10 {
  background-color: #ff4301;
 
  border-end-start-radius: 10px;
  border-start-start-radius: 10px;
}

.btn-scale-asc-2,
.btn-scale-desc-9 {
  background: linear-gradient(to left, #ff8501, #ff4301);
 
}

.btn-scale-asc-3,
.btn-scale-desc-8 {
  background: linear-gradient(to left, #ff8501, #ff8501);
  
  background-color: #99FF00;
}

.btn-scale-asc-4,
.btn-scale-desc-7 {
  background: linear-gradient(to left, #ff9f00, #ff8501);
  background-color: #CCFF00;
}

.btn-scale-asc-5,
.btn-scale-desc-6 {
  background: linear-gradient(to left, #ffb501, #ff9f00);
  background-color: #FFFF00;
}

.btn-scale-asc-6,
.btn-scale-desc-5 {
  background: linear-gradient(to left, #ecbd00, #ffb501);
  background-color: #FFCC00;
}

.btn-scale-asc-7,
.btn-scale-desc-4 {
  background: linear-gradient(to left, #c4ba01, #ecbd00);
  background-color: #FF9900;
}


.btn-scale-asc-8,
.btn-scale-desc-3 {
  background: linear-gradient(to left, #97b700, #c4ba01);
  background-color: #FF6600;
}


.btn-scale-asc-9,
.btn-scale-desc-2 {
  background: linear-gradient(to left, #74b500, #97b700);
  background-color: #FF3300;
}


.btn-scale-asc-10,
.btn-scale-desc-1 {
  background: linear-gradient(to left, #3cb100, #74b500);
  border-end-end-radius: 10px;
  border-start-end-radius: 10px;
}



a:hover,
a:focus,
.btn:hover,
.btn:focus {
  text-decoration: none;
  transition: all 0.3s ease;
  outline: 0;
}

a {
  transition: all 0.3s ease;
  outline: 0;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  outline: 0;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}

.topBar {
  text-align: center;
  padding: 15px 0 12px;
  background: #F8F8F8;
  font-size: 17px;
  font-weight: 500;
}

.navbar-brand img {
  width: 320px;
}

.navbar-expand-md .navbar-collapse {
  display: block;
}

.bg-light {
  background: rgba(0, 0, 0, 0.0) !important;
}

.navbar {
  padding: 12px 0;
  background: #fff !important;
}

.navbar-nav .nav-item:last-child .btn {
  margin-left: 20px !important;
}

.nav-item .btn {
  padding: 8px 20px !important;
  margin-left: 12px;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-size: 15px;
  padding: 8px 14px;
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link:hover {
  background: none;
  color: #AA1E54;
}

.navbar-light .navbar-nav .nav-link.btn:hover {
  background: #222222;
  border-color: #222222;
}

.navbar-light .navbar-nav .nav-link.btn.secondary:hover {
  background: #fdedf3;
  border-color: #AA1E54;
}

.navbar-light .navbar-nav .active>.nav-link {
  color: #fff !important;
  background: linear-gradient(to right, #FC3B5A 0%, #F9772E 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.navbar-light .navbar-nav .active>.nav-link:hover {
  color: #fff !important;
  background: linear-gradient(to right, #FC3B5A 0%, #F9772E 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.carousel-caption {
  text-align: center !important;
  top: 180px;
}

.nav-link.loginLink {
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding: 8px 12px !important;
}

.nav-link.loginLink:hover {
  border: 1px solid #00d2eb;
}

.navbar-light .navbar-nav .dropdown-menu a.dropdown-item {
  padding: 7px 15px;
  font-size: 14px;
  color: #fff;
}

.navbar-light .navbar-nav .dropdown-menu a.dropdown-item:hover,
.navbar-light .navbar-nav .dropdown-menu a.dropdown-item:focus {
  background: none;
  color: #c5bfee;
}

.navbar-light .navbar-nav .dropdown-menu a.dropdown-item.dropdown-toggle {
  padding: 0 !important;
  position: absolute;
  width: 37px;
  height: 39px;
  top: -39px;
  right: 0;
}

.collapse:not(.show) {
  display: none;
}

::placeholder {
  color: #777;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #777;
}

.whiteBg {
  background: #fff;
}

.greyBg {
  background: #f9f9f9;
}

.noMargin {
  padding-top: 0 !important;
}

.estimated {
  padding-top: 0 !important;
  margin-top: -20px;
}

img {
  max-width: 100%;
}

.btn {
  padding: 12px 40px;
  background: #AA1E54;
  color: #fff !important;
  border-radius: 40px;
  display: inline-block;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.4px;
  cursor: pointer;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  border: 1px solid #AA1E54;
  outline: none;
}

.btn:hover {
  background: #222222;
  border-color: #222222;
  color: #fff !important;
}

.btn.secondary {
  background: #fff;
  color: #AA1E54 !important;
}

.btn:focus {
  border: none;
  outline: none;
}

.banner {
  display: block;
  width: 100%;
  background: #AA1E54;
  color: #fff;
  padding-top: 70px;
  padding-bottom: 180px;
  position: relative;
  min-height: 650px;
}

.banner.tankyouBanner {
  min-height: auto;
}

.banner .container {
  position: relative;
  z-index: 2;
}

.bannerCurveBG {
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: -2px;
  z-index: 1;
}

.bannerCurveBG img {
  width: 100%;
  height: auto;
}

.bannerData {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.thankyou {
  display: block;
  text-align: center;
}

.bannerData h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 18px;
}

.bannerData span {
  font-weight: 700;
  color: #FFBE41;
  font-size: 46px;
  display: block;
}

.bannerData p {
  font-size: 17px;
  width: 600px;
  max-width: 100%;
  margin-bottom: 32px;
}

.btnWrapper {
  display: flex;
  gap: 26px;
}

.bannerData .btn {
  background: #FFBE41;
  border-color: #FFBE41;
  color: #000 !important;
  padding: 12px 20px;
  min-width: 232px;
}

.bannerData .btn:hover {
  background: #fff;
  border-color: #fff;
  color: #000 !important;
}

.bannerData .btn.secondary {
  background-color: #AA1E54;
  color: #FFBE41 !important;
}

.bannerData .btn.secondary:hover {
  background-color: #AA1E54;
  color: #fff !important;
}

.bannerRight {
  padding: 30px;
  border-radius: 15px;
  background: #fff;
  width: 340px;
  max-width: 100%;
  color: #000;
}

.bannerRight h4 {
  font-size: 20px;
  font-weight: 600;
}

.wrapper {
  width: 100%;
  display: block;
  min-height: 300px;
}

.hightligthSection {
  margin-top: -210px;
  padding-bottom: 10px;
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
}

.heightLight {
  display: flex;
  gap: 30px;
  position: relative;
  width: max-content;
}

.heightLightAll {
  position: absolute;
  right: -70px;
  bottom: 22px;
}

.heightLightAll span {
  background: #FFBE41;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  cursor: pointer;
  color: #111111;
}

.heightLightAll span:hover {
  background: #AA1E54;
  color: #fff;
}

.heightLightInner {
  padding: 25px;
  background: #fff;
  border-radius: 30px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  width: 130px;
  align-items: center;
  text-align: center;
}

.hideDesktop {
  display: none;
}

.heightLightInner span {
  font-weight: 700;
  font-size: 18px;
  margin: 12px 0 4px;
}

.heightLightInner em {
  line-height: 20px;
  font-style: normal;
}

.hightLightBorder {
  padding: 1px 1px 2px;
  border-radius: 32px;
  background: linear-gradient(to bottom, #f0edee 70%, #FF007A 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.20);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.20);
}

.heightLight .hightLightBorder:nth-child(2) {
  background: linear-gradient(to bottom, #f0edee 70%, #0093E5 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.heightLight .hightLightBorder:nth-child(3) {
  background: linear-gradient(to bottom, #f0edee 70%, #FF620A 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.heightLightInner .iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #FDDEF3;
  margin: 0 !important;
}

.heightLight .hightLightBorder:nth-child(2) .iconBox {
  background: #D9EFFC;
}

.heightLight .hightLightBorder:nth-child(3) .iconBox {
  background: #FFE9DC;
}

.hightLightHead {
  font-size: 26px;
  letter-spacing: 0.2px;
  font-weight: 600;
  margin-bottom: 20px;
}

.wrapper h3.hightLightHead {
  color: #fff !important;
  margin-bottom: 20px !important;
  letter-spacing: 0.5px;
}

.wrapper section {
  padding-top: 55px;
}

.wrapper h1 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 18px;
  letter-spacing: 0.2px;
}

.wrapper h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
  color: #AA1E54;
}

.wrapper h4 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  color: #AA1E54;
}

.industry {
  margin-top: 20px;
}

.courserWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.bbaCourses {
  display: flex;
  gap: 20px;
}

.crsIcon {
  height: 84px;
  width: 84px;
  min-width: 84px;
  display: flex;
  padding: 20px;
  border-radius: 50%;
  background: #fff;
  border-bottom: 2px solid #BABABA;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.20);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.20);
}

.crsData p {
  position: relative;
}

.crsData a {
  font-weight: 600;
  text-decoration: underline;
  color: #AA1E54;
  position: absolute;
  right: 0;
  display: inline-block;
}

.studentImgWrap {
  display: block;
  padding-left: 10px;
}

.badges {
  background: #fff;
  padding: 55px 0;
  margin-top: 55px;
}

.badgesWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.whyChoose {
  text-align: center;
}

.whyChoose p,
.globalImm p {
  width: 980px;
  margin: 0 auto 15px;
  max-width: 100%;
}

.copaqP {
  width: 980px;
  margin: 0 auto 15px;
  max-width: 100%;
}

.compareBox {
  position: relative;
  display: flex;
  gap: 2px;
  width: 100%;
  flex-direction: column;
  text-align: left;
  margin-top: 45px;
  margin-bottom: 50px;
  padding: 4px 0;
}

.compareBoxRow {
  display: flex;
  gap: 2px;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 500;
}

.compareCol-1 {
  padding: 8px 10px 8px 22px;
  background: #FFDCE9;
  color: #AA1E54;
  width: 19%;
  border-radius: 8px 0 0 8px;
}

.compareCol-2 {
  padding: 8px 10px 8px 20px;
  width: 32%;
  background: #ffffff;
}

.compareCol-3 {
  padding: 8px 10px 8px 20px;
  width: 49%;
  background: #ffffff;
}

.lastComRow .compareCol-3 {
  border-bottom-right-radius: 22px;
}

.highLightBorder {
  border: 3px #AA1E54 solid;
  position: absolute;
  height: 100%;
  width: 49%;
  right: 0;
  top: 0;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
}

.highLightBorder span {
  background: #AA1E54;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #fff;
  width: 44px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  left: -24px;
  top: 17px;
}

.highLightBorder span::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 90px;
  z-index: -1;
  background: #BFBFBF;
}

.topRow .compareCol-1 {
  background: none;
}

.topRow .compareCol-2,
.topRow .compareCol-3 {
  background: none;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 26px 0 22px;
}

.centerText {
  text-align: center;
}

.heightLightWrppaer {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5%;
  margin-top: 30px;
}

.heightLightWrppaer .gradientBox {
  width: 23.12% !important;
  margin-bottom: 30px;
}

.gradientBox {
  padding: 1px 1px 2px;
  border-radius: 32px;
  background: linear-gradient(to bottom, #E9E6E7 70%, #8A8A8A 100%);
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  width: 25%;
}

.gradientBoxInner {
  padding: 25px;
  background: #fff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px
}

.gradientBoxInner h4,
.gradientBoxInner p {
  margin-bottom: 0;
}

.linkText {
  color: #AA1E54;
  text-decoration: underline;
  font-weight: 600;
}

.linkText:hover {
  color: #111111;
  text-decoration: underline;
}

.moreHightlights {
  display: none;
}

.showAllhightLight {
  flex-direction: column;
  align-items: center;
  width: 75px;
  height: 75px;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  background: #AA1E54;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
}

.showAllhightLight:hover {
  background: #222222;
}

.showAllhightLight .fa {
  font-size: 27px;
  margin-bottom: -7px;
}

.showAllcourses {
  flex-direction: column;
  align-items: center;
  width: 60px;
  height: 60px;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  background: #FFBE41;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  letter-spacing: 0;
  font-weight: 600;
  cursor: pointer;
  margin-top: -63px;
  position: relative;
  z-index: 2;
  border: 3px solid #fff;
}

.showAllcourses:hover {
  background: #222222;
}

.showAllcourses .fa {
  font-size: 22px;
  margin-bottom: -7px;
}

.rotateArrow .fa {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.globalImm {
  margin-top: 55px;
  padding-bottom: 55px;
  background-color: #fff;
}

.globalImmWrapper {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.globalImmWrapper .gradientBox {
  width: 50%;
}

.globalImmWrapper .gradientBoxInner {
  padding: 0;
  overflow: hidden;
}

.globalImmWrapper h4 {
  color: #000;
  font-weight: 600;
  margin-bottom: 14px;
}

.globaldata {
  padding: 0 30px 30px;
}

.globaldata ul {
  display: block;
  margin-bottom: 0;
  padding-left: 10px;
}


.globaldata ul li:last-child {
  margin-bottom: 0;
}

.globaldata h3 {
  text-align: center;
  font-size: 18px;
  color: #000;
  margin-top: -50px;
  margin-bottom: 20px;
}

.globaldata h3 span {
  color: #AA1E54;
  font-weight: 700;
  font-size: 26px;
}

.miniLogo {
  margin-bottom: 18px;
}

.btnOtr {
  text-align: center;
  margin-top: 50px;
}

.btnOtrCousrs {
  text-align: center;
  margin-top: 35px !important;
}

.btnOtrCousrs1 {
  text-align: center;
  margin-top: 20px !important;
}

.tabsWrapper {
  display: block;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

.tabsWrapper .nav-tabs {
  border-bottom: 0px none;
  padding-left: 0;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}

.tabsWrapper .nav-tabs .nav-item {
  margin-bottom: 0px;
}

.tabsWrapper .nav-tabs .nav-link {
  background: none;
  color: #111111;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.4px;
  padding: 16px 22px 24px;
  border: 0px none;
  margin-bottom: -10px;
}

.tabsWrapper .nav-tabs .nav-link.active {
  box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 15px 15px 0 0;
  color: #fff;
  border: 0px none;
  background-color: #AA1E54;
}

.tabsWrapper .nav-tabs .nav-link:focus {
  outline: none;
}

.tab-content h3 {
  font-weight: 700 !important;
  margin-bottom: 15px;
}

.primaryText {
  color: #AA1E54;
}

.crsLu li.primaryText {
  font-weight: 600;
}

.crsLu li b {
  font-weight: 600;
}

.tabsWrapper .tab-content {
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 30px;
  background: #fff;
  position: relative;
  z-index: 2;
  border-bottom: 2px #a5a5a5 solid;
}

.proCertificate {
  display: block;
  padding-bottom: 55px;
  background: linear-gradient(to bottom, #f9f9f9 44%, #f9f9f9 44%, #f9f9f9 44%, #ffffff 45%);
}

.proCertificateInner {
  background: #AA1E54;
  border-radius: 30px;
  padding: 0 45px;
  display: flex;
  gap: 50px;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15);
}

.proCertLeft {
  padding: 40px 0 25px;
  color: #fff;
}

.proCertLeft h2 {
  font-size: 28px;
  line-height: 42px;
}

.proCertLeft p {
  margin-top: 15px;
  font-size: 16px;
}

.proCertLeft h2 span {
  font-size: 40px;
  font-weight: 700;
  color: #FFBE41;
}

.proCertRight {
  margin-top: -115px;
}

.proCertRight img {
  width: 460px;
}

.courses {
  padding-bottom: 60px;
}

.topPadding {
  padding-top: 60px;
}

.courses h2 {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
}

.wrapper .courses h3 {
  margin-top: 0;
}

.courses p {
  width: 900px;
  max-width: 100%;
}

.placements h2 {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
}

.wrapper .placements h3 {
  margin-top: 0;
}

.placements p {
  width: 900px;
  max-width: 100%;
  margin-bottom: 10px;
}

.coursetWrppaer {
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
  margin-top: 30px;
}

.coursetWrppaer .gradientBox {
  width: 31.33%;
  margin-bottom: 30px;
}

.coursetWrppaer .gradientBoxInner {
  padding: 25px;
}

.moreCourses,
.moreCoursesbusiness {
  display: none;
}

.crsTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.coarPart b {
  background: #FFDCE9;
  border-radius: 4px;
  padding: 4px 8px;
}

.crsLu {
  margin-bottom: 0;
  padding-left: 0;
  display: block;
}


.crsLu li:last-child {
  margin-bottom: 10px;
}

.placements .gradientBoxInner {
  padding: 4px;
}

.placementdata {
  padding: 16px 24px 24px;
}

.placementdata h3 {
  font-weight: 700;
  line-height: 32px;
}

.placementImg {
  overflow: hidden;
  border-radius: 27px;
}

.logoSlider {
  padding-bottom: 55px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logoSlider section {
  padding-top: 0;
}

.logoWrappe {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.logoWrappe {
  display: inline-flex;
}

.eligility {
  background: #fff;
  padding: 55px 0;
}

.eligibilityWrapper {
  width: 1000px;
  margin: 0 auto;
  position: relative;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.eliWrapInner {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  z-index: 2;
}

.eliWrapInnerLeft {
  height: 150px;
  width: 150px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFDEEB;
  border: 5px solid #fff;
  border-radius: 50%;
}

.eliWrapInnerRight p {
  margin-bottom: 0;
}

.eliWrapInnerRight p b {
  display: block;
  margin-top: 10px;
}

.eliWrapInnerRight ul {
  margin-bottom: 2px !important;
  padding-left: 30px;
}

.eliWrapInnerRight h3 {
  margin-bottom: 6px !important;
}

.VerticalLine {
  height: 100%;
  width: 3px;
  border-radius: 3px;
  background-color: #222;
  position: absolute;
  left: 76px;
  top: 0;
  z-index: 1;
}

.aboutSecn {
  background: #fff;
  padding: 55px 0;
}

.aboutWrapper {
  display: flex;
  gap: 30px;
  align-items: center;
}

.aboutLeft {
  width: 50%;
}

.aboutLeft p {
  margin-bottom: 12px;
}

.aboutLeft p:last-child {
  margin-bottom: 0px;
}

.aboutRight img {
  border-radius: 15px;
}

footer {
  background: #231F20;
  padding: 40px 0;
  color: #fff;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.adress {
  width: 330px;
}

.copyText {
  white-space: nowrap;
}

.footLogo img {
  width: 250px;
}

.gallary {
  padding-bottom: 60px;
}

.modal-title {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.gallaryWrapper .carousel {
  width: 850px;
  max-width: 100%;
  margin: 0 auto;
}

.gallaryWrapper .carousel-control-prev {
  width: 80px;
  left: -80px;
  color: #AA1E54;
  font-size: 30px;
}

.gallaryWrapper .carousel-control-next {
  width: 80px;
  right: -80px;
  color: #AA1E54;
  font-size: 30px;
}

.gallaryWrapper .carousel-item img {
  border-radius: 15px;
  width: 100%;
}

.stickyBtn {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 5;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 144px;
  padding: 11px 0px;
  font-size: 15px;
  border-radius: 20px 20px 0 0;
  margin-right: -49px;
  border-color: #fff;
}

#back2Top {
  background: #FFBE41;
  color: #fff;
  border-radius: 50%;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 3;
  display: none;
}

#back2Top span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  font-size: 20px;
}

i {}

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: auto;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.mobilebtn {
  display: none;
}

.thankData {
  text-align: center;
  align-items: center;
  padding: 65px;
}

.thankData .heightLightInner {
  width: 800px;
  max-width: 100%;
  align-items: flex-start;
  padding: 35px;
}

.thankData .heightLightCurve {
  text-align: center;
}

.bannerData.thankyou h1 {
  line-height: 56px;
}

.bannerData.thankyou h1 span {
  font-size: 40px;
}

.thanksPopup {
  text-align: left;
  font-size: 15px;
}

.thanksPopup p {
  margin-bottom: 8px;
}

.thanksPopup span {
  font-weight: 500;
  font-size: 15px;
  color: #AA1E54;
}

.thanksPopup span a {
  text-decoration: underline;
  color: #AA1E54;
}

.thanksPopup span a:hover {
  color: #111;
}

.thanksPopup h3 {
  padding-top: 15px;
}

.thankData .bannerRight {
  border: 1px solid #e3e3e3;
  margin: 20px auto 0;
}

@media only screen and (max-width: 1300px) {
  .container {
    max-width: 100% !important;
    padding: 0 20px;
  }

  .navbar-brand img {
    width: 250px;
  }

  .bannerRight {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 1140px) {
  .navbar-nav .nav-item:nth-last-child(2) {
    display: none;
  }

  .navbar-nav .nav-item:last-child .btn {
    margin-left: 10px !important;
  }

  .bannerData h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .bannerData span {
    font-size: 38px;
  }

  .wrapper h3.hightLightHead {
    margin-top: 20px;
    margin-bottom: 25px !important;
    font-size: 24px;
  }

  .wrapper h1 {
    font-size: 32px;
    margin-bottom: 14px;
  }

  .wrapper h3 {
    font-size: 22px;
  }

  .wrapper h4 {
    font-size: 17px;
  }

  .crsData a {
    position: static;
  }

  .eligibilityWrapper {
    max-width: 100%;
  }

  .wrapper section {
    padding-top: 45px;
  }

  .badges {
    padding: 45px 0;
    margin-top: 45px;
  }

  .badgesInn img {
    width: 180px;
    max-width: 100%;
  }

  .topRow .compareCol-2,
  .topRow .compareCol-3 {
    font-size: 18px;
  }

  .compareBox {
    margin-bottom: 45px;
  }

  .wrapper h4 {
    font-size: 16px;
  }

  .wrapper h3 {
    font-size: 18px;
  }

  .globaldata h3 {
    margin-top: -40px;
  }

  .globaldata h3 span {
    font-size: 20px;
  }

  .globaldata ul {
    padding-left: 5px;
  }

  .tabsWrapper .nav-tabs {
    margin-top: 20px;
  }

  .proCertLeft h2 span {
    font-size: 30px;
  }

  .proCertLeft h2 {
    font-size: 22px;
    line-height: 34px;
  }

  .proCertificateInner {
    padding: 0 30px;
    gap: 25px;
    align-items: flex-end;
  }

  .proCertLeft p {
    margin-top: 10px;
  }

  .proCertificate {
    padding-bottom: 45px;
  }

  .courses {
    padding-bottom: 50px;
  }

  .logoSlider section {
    padding-top: 0px !important;
  }

  .logoSlider {
    padding-bottom: 45px;
  }

  .globalImmWrapper {
    gap: 24px;
  }

  .coursetWrppaer {
    gap: 2%;
  }

  .coursetWrppaer .gradientBox {
    width: 32%;
  }

  .estimated {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 992px) {
  .bannerLeft p {
    display: none;
  }

  .bannerData h1 {
    font-size: 30px;
    line-height: 44px;
    margin-bottom: 35px;
  }

  .bannerData span {
    font-size: 34px;
  }

  .bannerData {
    flex-direction: column;
    align-items: center;
  }

  .bannerRight {
    margin: 0 auto;
  }

  .bannerLeft {
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
  }

  .btnWrapper {
    gap: 24px;
    justify-content: center;
  }

  .bannerRight {
    width: 370px;
  }

  .banner {
    padding-top: 45px;
  }

  .bannerCurveBG {
    display: none;
  }

  .hightligthSection {
    margin-top: -145px;
  }

  .studentImgWrap {
    padding: 30px 40px 0;
    text-align: center;
  }

  .badgesWrapper {
    gap: 25px;
  }

  .aboutSecn {
    padding: 45px 0;
  }

  .globalImm {
    margin-top: 45px;
    padding-bottom: 45px;
  }

  .studentImgWrap img {
    width: 350px;
    max-width: 100%;
  }

  .hightligthSection.thankData {
    margin-top: -195px;
  }

  .thankData .heightLightCurve {
    width: 100%;
  }

  .thankData {
    padding: 20px;
  }

  .thankData .heightLight {
    display: block;
    width: 100%;
  }

  .bannerData.thankyou h1 span {
    font-size: 31px;
  }

  .bannerData.thankyou h1 {
    line-height: 44px;
  }
}

@media only screen and (max-width: 991px) {
  .footerWrapper {
    gap: 30px;
    flex-direction: column;
  }

  .adress {
    width: auto;
    text-align: center;
  }

  .highLightBorder {
    display: none;
  }

  .compareBox {
    gap: 0;
    margin-bottom: 35px;
    margin-top: 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
  }

  .compareCol-1 {
    padding: 8px 10px 8px 17px;
    width: 100%;
    border-radius: 0;
    font-weight: 600;
  }

  .compareCol-2 {
    width: 40%;
    position: relative;
  }

  .compareCol-3 {
    width: 60%;
    position: relative;
  }

  .compareCol-3:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
    left: 0;
    top: 0;
    position: absolute;
  }

  .compareCol-2:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
    right: -1px;
    top: 0;
    position: absolute;
  }

  .topRow {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #111 !important;
  }

  .topRow .compareCol-1 {
    display: none;
  }

  .compareBoxRow {
    flex-wrap: wrap;
    gap: 0;
    background: #fff;
  }

  .topRow .compareCol-2,
  .topRow .compareCol-3 {
    padding: 10px 17px 10px;
    background: #111;
    color: #fff;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: left;
  }

  .lastPara {
    margin-bottom: 10px !important;
  }

  .heightLight {
    gap: 24px;
  }

  .gallaryWrapper .carousel-control-next {
    width: 60px;
    right: 0;
    color: #ffffff;
    opacity: 0.75;
  }

  .gallaryWrapper .carousel-control-prev {
    width: 60px;
    left: 0;
    color: #ffffff;
    opacity: 0.75;
  }

  .aboutWrapper {
    flex-direction: column;
  }

  .aboutLeft {
    width: 100%;
  }

  .footerWrapper {
    gap: 18px;
  }

  .navbar>.container {
    flex-direction: column;
    gap: 20px
  }

  .heightLightWrppaer {
    gap: 3%;
  }

  .heightLightWrppaer .gradientBox {
    width: 450px !important;
    margin-bottom: 25px;
    max-width: 100%;
  }

  .heightLightWrppaer {
    gap: 0;
    flex-direction: column;
    align-items: center;
  }

  .globalImmWrapper .gradientBox {
    width: 500px;
    max-width: 100%;
  }

  .globalImmWrapper {
    gap: 30px;
    flex-direction: column;
    align-items: center;
  }

  .coursetWrppaer {
    gap: 0;
    flex-direction: column;
    align-items: center;
  }

  .coursetWrppaer .gradientBox {
    width: 500px;
    max-width: 100%;
  }

  .placementImg img {
    width: 100%;
  }

  .VerticalLine {
    display: none;
  }

  .eliWrapInner {
    flex-direction: column;
  }

  .eliWrapInnerLeft {
    height: 115px;
    width: 115px;
    min-width: 115px;
  }

  .eliWrapInnerLeft img {
    width: 65px;
    height: auto;
  }

  .eliWrapInnerRight {
    text-align: center;
  }

  .eligibilityWrapper {
    gap: 32px;
    width: 500px;
    max-width: 100%;
    padding: 0;
  }

  .eliWrapInnerRight ul {
    text-align: left;
    margin-left: 20px;
  }

  .eligility {
    padding: 45px 0;
  }

  .tabsWrapper .nav-tabs .nav-link {
    padding: 10px 15px 10px;
    border-radius: 10px !important;
    margin-bottom: 5px;
  }

  .tabsWrapper .nav-tabs {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .tabsWrapper .nav-tabs .nav-item {
    white-space: nowrap;
  }

  .proCertificateInner {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .proCertLeft {
    padding: 30px 0 20px;
  }

  .proCertRight {
    margin-top: 0;
  }

  .proCertRight img {
    width: 200px;
    max-width: 100%;
  }

  .copyText {
    white-space: normal;
    text-align: center;
    font-size: 13px;
    opacity: 0.7;
  }

  .thanksPopup h3 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) {



.pt-31{
  background-image: url('images/bg_ginger3.png');
  height: 250px;
  background-size: cover;
  /* background-repeat: repeat; */
  /* background-position:fixed; */
  align-items: center;
  /* margin-left: 30%; */
  border-radius: 20px;
  border-bottom: solid;
  border-color: rgba(81, 21, 21, 0.666);
}
.pt-31 h3{
  /* margin-top: 10px; */
  /* margin-left: 30%; */
  color: rgb(81, 21, 21);
}
.pt-text{
  width: 90%;
  /* margin-left: 23%; */
  /* font-size: 12px; */
}
.footer_text{
  background-color: #2c451f;
  color: #ffffff;
  height: 1%;
  padding: 13px;
  margin-top: 30px;
}
.circle1{
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 30px;
  border: solid;
  border: 1;
  border-color: rgba(81, 21, 21, 0.666);
  /* margin-left: 45%; */
  color: rgb(81, 21, 21);
  /* align-items: center; */
  padding: 5px;
  /* font-size: 8px; */
}
/* .carousel h3{
  margin: 20px;
} */
.circles{
  /* display: flex; */
  margin-top: -25px;
  /* padding: 10%; */
  width: 90%;
  display: contents;
  
}

.pha{
  width: 90%;
  align-items: center;
  /* margin: 5%; */
  /* margin-top: -0px; */
}


.result_heading h4{
  margin-left: 15%;
  /* margin-top: 50%; */
}













  .result-box-1 {
    padding: 12px 30px 0.2px 29px;
  }

  .container-fluid {
    padding: 0;
  }
  .modal-content{
    top: 0%;
  }
  .uploader .modal-dialog {
    margin: 0;
  }

  .uploader .modal-content {
    border: 0;
  }
  .img-camera{
    height: 50px;
    top: 10%;
  }
  .img-camera-bg{
    height: 90px;
  }

  .result-scale {
    font-size: 11px;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .container {
    padding: 0;
  }

  .logo {
    padding-top: 9px;
    text-align: left !important;
    padding-left: 16px;
    font-size: 36px;
    height: 150px;
  }

  body {
    letter-spacing: inherit;
  }

  .navbar {
    padding: 10px 18px;
  }

  .topBar {
    padding: 10px 10px 8px;
    font-size: 13px;
  }

  .navbar>.container {
    flex-direction: row;
    gap: 0;
  }

  .navbar-light .navbar-nav .nav-link {
    border-top: 1px solid #d3d3d3;
    padding: 10px 0px;
  }

  .navbar-nav li:first-child a.nav-link {
    margin-top: 10px;
  }

  .navbar-nav .nav-item:last-child .btn {
    margin-left: 0px !important;
    width: 100%;
    text-align: left;
    padding-left: 0 !important;
    border: 0 none;
    background: none;
    color: #AA1E54 !important;
    border-top: 1px solid #d3d3d3;
    border-radius: 0;
    padding-top: 10px !important;
    padding-bottom: 0 !important;
  }

  .navbar-nav .nav-item:last-child .btn:hover {
    background: none !important;
  }

  .globaldata ul li {
    background-size: 11px;
    background-position: left 8px;
    padding-left: 19px;
  }

  .navbar-toggler {
    padding: 0;
    border: 0px none;
  }

  .bannerLeft {
    text-align: left;
    margin-bottom: 0;
  }

  .btnWrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .bannerData .btn {
    padding: 10px 12px;
    min-width: 234px;
    width: 234px;
    max-width: 100%;
  }

  .bannerData h1 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
    font-weight: 600;
  }

  .bannerData span {
    font-size: 30px;
    font-weight: 800;
  }

  .wrapper h3.hightLightHead {
    color: #000000 !important;
    text-align: center;
    font-size: 22px;
    margin-top: 0;
  }

  .hightligthSection {
    margin-top: 0;
    align-items: center;
  }

  .banner {
    padding-bottom: 0;
    padding-top: 40px;
    min-height: auto;
  }

  .bannerRight {
    margin-bottom: -150px;
    padding: 24px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  }

  .wrapper h3.hightLightHead {
    margin-bottom: 15px !important;
  }

  .wrapper h1 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .wrapper {
    margin-top: 175px;
  }

  .heightLight {
    gap: 0;
  }

  .heightLightCurve {
    padding: 1px 1px 2px;
    border-radius: 15px;
    background: linear-gradient(to bottom, #f0edee 70%, #FF007A 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    width: 360px;
    max-width: 100%;
  }

  .heightLightCurve .heightLight {
    padding: 25px 20px;
    background: #fff;
    border-radius: 13px;
    font-size: 13px;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .hightLightBorder {
    width: 33.33%;
    background: none !important;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
  }

  .hideMobile {
    display: none !important;
  }

  .hideDesktop {
    display: block;
  }

  .heightLightCurve .heightLight .heightLightInner {
    width: 100%;
    padding: 0 0 13px;
  }

  .mobilebtn {
    display: block;
  }

  .heightLightAll span {
    border-radius: 40px;
    height: 38px;
    width: 120px;
    background: #AA1E54;
    color: #fff;
    align-items: center;
    gap: 10px;
  }

  .heightLightAll span em {
    font-style: normal;
    font-size: 14px;
  }

  .heightLightAll {
    right: 50%;
    bottom: -19px;
    margin-right: -60px;
  }

  .stickyBtn {
    position: fixed;
    right: 0;
    top: auto;
    bottom: 0;
    z-index: 5;
    background: #FFBE41 !important;
    -webkit-transform: rotate(-0deg);
    -moz-transform: rotate(-0deg);
    -o-transform: rotate(-0deg);
    -ms-transform: rotate(-0deg);
    transform: rotate(-0deg);
    width: 100%;
    padding: 14px 0px;
    font-size: 15px;
    border-radius: 0;
    margin-right: 0;
    border: none 0px;
    border-top: 1px solid #fff;
  }

  #back2Top {
    z-index: 7;
    background-color: #fff;
    border: 1px solid #f9f9f9;
    color: #AA1E54;
    -webkit-box-shadow: 0 4px 9px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 9px 0px rgba(0, 0, 0, 0.1);
  }

  #back2Top span {
    height: 40px;
    width: 40px;
    font-size: 15px;
  }

  body {
    padding-bottom: 45px;
  }

  .studentImgWrap {
    padding: 10px 40px 0;
    text-align: center;
  }

  .navbar-brand {
    margin: 0 !important;
  }

  .navbar-brand img {
    width: 220px;
  }

  .courserWrapper {
    flex-direction: row;
    overflow: scroll;
    flex-wrap: nowrap;
  }

  .bbaCourses {
    width: 92%;
    min-width: 92%;
  }

  .crsIcon {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
  }

  .crsData p {
    margin-bottom: 0;
  }

  .crsData a {
    position: static;
    display: block;
    padding-top: 8px;
  }

  .wrapper section {
    padding-top: 35px;
  }

  .badges {
    padding: 30px 0 15px;
    margin-top: 40px;
  }

  .badgesWrapper {
    gap: 30px;
    overflow: scroll;
  }

  .badgesInn {
    min-width: 135px;
    width: 135px;
    margin-bottom: 15px;
  }

  .compareBox {
    margin-top: 20px;
  }

  .whyChoose .container {
    padding: 0 8px;
  }

  .whyChoose h1 {
    padding: 0 20px;
  }

  .whyChoose p,
  .globalImm p {
    text-align: left;
  }

  .lastPara {
    margin-bottom: 20px !important;
  }

  .globaldata h3 {
    margin-top: -21px;
  }

  .globaldata {
    padding: 0 24px 24px;
  }

  body {
    font-size: 15px;
  }

  .proCertRight img {
    width: 118px;
    max-width: 100%;
  }

  .proCertLeft {
    padding: 30px 0 0;
  }

  .heightLightWrppaer {
    flex-wrap: nowrap;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-direction: row;
    margin-top: 20px;
  }

  .heightLightWrppaer .gradientBox {
    min-width: 100% !important;
    margin-bottom: 0;
  }

  .moreHightlights {
    display: block !important;
  }

  .showAllhightLight {
    display: none;
  }

  .globalImm {
    margin-top: 35px;
    padding-bottom: 3px;
  }

  .coursetWrppaer {
    gap: 24px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
  }

  .coursetWrppaer .gradientBox {
    min-width: 100%;
  }

  .moreCourses {
    display: block !important;
  }

  .showAllcourses {
    display: none !important;
  }

  .placements p {
    text-align: left;
  }

  .coursetWrppaer {
    margin-top: 20px;
  }

  .coursetWrppaer .gradientBox {
    margin-bottom: 12px;
  }

  .btn {
    padding: 10px 30px;
    font-size: 16px;
  }

  .eliWrapInner {
    align-items: flex-start;
    min-width: 90%;
    padding: 0 14px;
  }

  .eliWrapInnerRight {
    text-align: left;
    gap: 20px;
  }

  .eligibilityWrapper {
    margin-top: 26px;
    gap: 0;
    overflow: auto;
    flex-direction: row;
    min-width: calc(100% - -30px);
    margin-left: -15px;
  }

  .eliWrapInnerLeft {
    border: 0px none;
  }

  .eliWrapInnerRight ul {
    margin-left: 0px;
    line-height: 20px;
  }

  .eliWrapInnerRight ul li {
    margin-bottom: 4px;
  }

  .proCertLeft h2 span {
    font-size: 22px;
    font-weight: 800;
  }

  .proCertLeft h2 {
    font-size: 19px;
    line-height: 28px;
    font-weight: 600;
  }

  .proCertLeft p {
    font-size: 15px;
  }

  .proCertificateInner {
    padding: 0 24px;
    border-radius: 15px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  }

  .proCertLeft {
    padding: 25px 0 0;
  }

  .courses h2 {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .btnWrapper {
    display: none;
  }

  .gradientBoxInner {
    padding: 24px;
    border-radius: 13px;
  }

  .gradientBox {
    border-radius: 15px;
  }

  .globalImmWrapper {
    margin-top: 20px;
    gap: 24px;
  }

  .globaldata ul {
    padding-left: 0;
  }

  .gradientBox {
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  }

  .proCertificate {
    padding-bottom: 35px;
  }

  .btnOtr {
    margin-top: 35px;
  }

  .placementdata {
    padding: 16px 20px 20px;
  }

  .eligility {
    padding: 35px 0;
  }

  .gallary {
    padding-bottom: 40px;
  }

  .placementImg {
    border-radius: 13px;
  }

  .placements .gradientBoxInner {
    padding: 2px;
  }

  .wrapper h3 {
    font-size: 16px;
  }

  .tabsWrapper .tab-content {
    padding: 24px;
  }

  .banner.tankyouBanner {
    padding-bottom: 150px;
  }

  .hightligthSection.thankData {
    margin-top: -325px;
    padding: 0;
    margin-bottom: 50px;
  }

  .bannerData.thankyou h1 span {
    font-size: 25px;
  }

  .bannerData.thankyou h1 {
    line-height: 36px;
  }

  .thankData .hightLightBorder {
    width: 100% !important;
  }

  .bannerData.thankyou h1 {
    line-height: 30px;
    font-size: 22px;
  }

  .bannerData.thankyou h1 span {
    font-size: 24px;
  }

  .thankData .heightLightInner {
    padding-bottom: 0 !important;
  }

  .thanksPopup h3 {
    font-size: 19px !important;
  }

  .thanksPopup {
    line-height: 22px;
  }
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}