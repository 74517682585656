.img-home-page {
    border-radius: 20px;
    margin: 5px;
    width: auto;
    border: 5px solid #e9e9e9;
    cursor: pointer;
}
.font-size-home-fruit{
    font-size: 14px;
  
}

.img-home-page:hover {
    transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
@media only screen and (max-width: 767px) {
    .logo {
        top:5px;
    }
    .modal-dialog {
        margin: 5px;
    }
}