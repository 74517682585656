
.modal {
    background-color: rgb(0 0 0 / 54%)
  }

  .txt-indictor::after {
    content: attr(data-number); /* Use the 'data-number' attribute for the number */
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%; /* Creates a circle */
    background-color: #40b309; /* Background color of the circle */
    color: white;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    margin-left: 5px; /* Adjust as needed to position the circle */
  }

@media only screen and (max-width: 767px) {
    .uploader > .modal {
      overflow-y: auto;
      position: relative;
      border: 0 #fff;
      padding-top: 10px;
    }

    .uploader > .bg-image-musk {
        background: #fff;
    
      }
    

}